import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemText,
  useTheme,
  useMediaQuery,
  Button,
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { RootState } from "../../redux/store";
import { Class, Attendee } from "../../redux/types/types";
import { useLanguage } from "../../components/LanguageProvider/LanguageProvider";
import EnrollmentModal from "./EnrollmentModal/EnrollmentModal";

export default function MyClasses() {
  const intl = useIntl();
  const user = useSelector((state: RootState) => state.auth.user);
  const isInstructor = useSelector(
    (state: RootState) => state.auth.isInstructor
  );
  const { language } = useLanguage();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [selectedClass, setSelectedClass] = useState<Class | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const getLocalizedValue = (
    obj: Record<string, string>,
    lang: string
  ): string => {
    return obj[lang] || obj["en"] || "";
  };

  const calculateAge = (birthdate: Date): number => {
    const today = new Date();
    const birthDate = new Date(birthdate);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const renderAttendees = (classItem: Class) => {
    return (
      <Box sx={{ mt: 2 }}>
        <Typography variant="subtitle2">
          <FormattedMessage
            id="myClasses.attendees"
            defaultMessage="Attendees"
          />
          :
        </Typography>
        <List sx={{ p: 0 }}>
          {classItem.attendees?.map((attendee: Attendee, index: number) => (
            <ListItem key={attendee.id} sx={{ px: 0 }}>
              <ListItemText
                primary={`${index + 1}. ${attendee.firstName} ${
                  attendee.lastName
                }`}
                secondary={`${calculateAge(attendee.birthdate)} years old, ${
                  attendee.sex
                }`}
              />
            </ListItem>
          ))}
        </List>
      </Box>
    );
  };

  const handleEnrollClick = (classItem: Class) => {
    setSelectedClass(classItem);
    setIsModalOpen(true);
  };

  const renderClassCard = (classItem: Class) => (
    <Card
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
      }}
    >
      <CardContent sx={{ flex: 1 }}>
        <Typography variant="h6" component="div">
          {getLocalizedValue(classItem.title, language)}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {intl.formatMessage({
            id: "myClasses.instructor",
            defaultMessage: "Instructor",
          })}
          : {classItem.instructor.firstname} {classItem.instructor.lastname}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {intl.formatMessage({ id: "myClasses.type", defaultMessage: "Type" })}
          : {getLocalizedValue(classItem.type?.name, language)}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {intl.formatMessage({
            id: "myClasses.location",
            defaultMessage: "Location",
          })}
          : {getLocalizedValue(classItem.location?.name, language)}
        </Typography>
        {isInstructor && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleEnrollClick(classItem)}
            sx={{ mt: 2 }}
          >
            <FormattedMessage
              id="myClasses.enrollAttendee"
              defaultMessage="Enroll Attendee"
            />
          </Button>
        )}
      </CardContent>
      {!isMobile && (
        <Box
          sx={{
            width: "30%",
            borderLeft: "1px solid",
            borderColor: "divider",
            p: 2,
          }}
        >
          {renderAttendees(classItem)}
        </Box>
      )}
      {isMobile && <Box sx={{ p: 2 }}>{renderAttendees(classItem)}</Box>}
    </Card>
  );

  return (
    <Box
      sx={{
        my: 4,
        mx: 2,
        width: "100%",
        maxWidth: "1200px",
        margin: "0 auto",
        pt: isMobile ? 5 : 0,
      }}
    >
      {user.classes.length === 0 ? (
        <Typography>
          <FormattedMessage
            id="myClasses.noClasses"
            defaultMessage="You are not enrolled in any classes."
          />
        </Typography>
      ) : (
        <Grid container spacing={2}>
          {user.classes.map((classItem) => (
            <Grid item xs={12} md={6} key={classItem.id}>
              {renderClassCard(classItem)}
            </Grid>
          ))}
        </Grid>
      )}
      <EnrollmentModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        classItem={selectedClass}
      />
    </Box>
  );
}
