import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import {
  Modal,
  Box,
  Typography,
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  Grid,
  Card,
  CardContent,
  useMediaQuery,
  Theme,
  Radio,
  RadioGroup,
  Alert,
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { Autocomplete } from "@mui/material";
import {
  enrollAttendee,
  fetchUsers,
  fetchAttendees,
  fetchSchedules,
} from "../../../redux/instructor/instructorOperations";
import { User, Attendee, Schedule, Class } from "../../../redux/types/types";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { getProfile } from "../../../redux/auth/authOperations";

interface EnrollmentModalProps {
  open: boolean;
  onClose: () => void;
  classItem: Class | null;
}

type UserData =
  | number
  | {
      email: string;
      firstName?: string;
      firstname?: string;
      lastName?: string;
      lastname?: string;
      phone?: string;
    };
type AttendeeData =
  | number
  | {
      firstName: string;
      lastName: string;
      birthdate: string | Date;
      sex: "male" | "female" | "other";
      phone: string;
      email: string;
    };

interface FormValues {
  user: UserData | null;
  attendee: AttendeeData | null;
  scheduleIds: number[];
}

const EnrollmentModal: React.FC<EnrollmentModalProps> = ({
  open,
  onClose,
  classItem,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const intl = useIntl();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const [users, setUsers] = useState<User[]>([]);
  const [attendees, setAttendees] = useState<Attendee[]>([]);
  const [schedules, setSchedules] = useState<Schedule[]>([]);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [selectedAttendee, setSelectedAttendee] = useState<Attendee | null>(
    null
  );
  const [isNewUser, setIsNewUser] = useState(false);
  const [isNewAttendee, setIsNewAttendee] = useState(false);
  const [backendError, setBackendError] = useState<string | null>(null);

  const clearAllStates = () => {
    setSelectedUser(null);
    setSelectedAttendee(null);
    setIsNewUser(false);
    setIsNewAttendee(false);
    setBackendError(null);
  };

  useEffect(() => {
    if (open && classItem) {
      dispatch(fetchUsers()).then((action) => {
        if (fetchUsers.fulfilled.match(action)) {
          setUsers(action.payload);
        }
      });
      dispatch(fetchSchedules(classItem.id)).then((action) => {
        if (fetchSchedules.fulfilled.match(action)) {
          setSchedules(action.payload);
        }
      });
    }
  }, [open, classItem, dispatch]);

  useEffect(() => {
    if (selectedUser && selectedUser.id) {
      dispatch(fetchAttendees({ userId: selectedUser.id })).then((action) => {
        if (fetchAttendees.fulfilled.match(action)) {
          setAttendees(action.payload);
        }
      });
    }
  }, [selectedUser, dispatch]);

  const validationSchema = Yup.object().shape({
    user: Yup.mixed().required(
      intl.formatMessage({ id: "enrollmentModal.userRequired" })
    ),
    attendee: Yup.mixed().required(
      intl.formatMessage({ id: "enrollmentModal.attendeeRequired" })
    ),
    scheduleIds: Yup.array()
      .of(Yup.number())
      .min(1, intl.formatMessage({ id: "enrollmentModal.scheduleRequired" })),
  });

  const initialValues: FormValues = {
    user: null,
    attendee: null,
    scheduleIds: [],
  };

  const handleSubmit = async (
    values: FormValues,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    setBackendError(null);
    if (values.user !== null && values.attendee !== null) {
      const attendeeData =
        typeof values.attendee === "number"
          ? values.attendee
          : {
              ...values.attendee,
              birthdate: new Date(values.attendee.birthdate),
            };

      try {
        await dispatch(
          enrollAttendee({
            user: values.user,
            attendee: attendeeData,
            scheduleIds: values.scheduleIds,
          })
        ).unwrap();
        await dispatch(getProfile());
        clearAllStates();
        onClose();
      } catch (error) {
        if (error instanceof Error) {
          setBackendError(error.message);
        } else {
          setBackendError(
            intl.formatMessage({ id: "enrollmentModal.unknownError" })
          );
        }
      } finally {
        setSubmitting(false);
      }
    }
  };

  const renderSchedules = (
    scheduleIds: number[],
    setFieldValue: (field: string, value: any) => void
  ) => {
    const weekdays = [
      intl.formatMessage({ id: "days.monday" }),
      intl.formatMessage({ id: "days.tuesday" }),
      intl.formatMessage({ id: "days.wednesday" }),
      intl.formatMessage({ id: "days.thursday" }),
      intl.formatMessage({ id: "days.friday" }),
      intl.formatMessage({ id: "days.saturday" }),
      intl.formatMessage({ id: "days.sunday" }),
    ];
    const groupedSchedules = weekdays
      .map((day, index) => ({
        day,
        schedules: schedules.filter((schedule) => schedule.dayOfWeek === index),
      }))
      .filter(({ schedules }) => schedules.length > 0);

    return groupedSchedules.map(({ day, schedules }) => (
      <Box key={day} sx={{ mb: 2 }}>
        <Typography variant="subtitle2">{day}</Typography>
        {schedules.map((schedule) => (
          <FormControlLabel
            key={schedule.id}
            control={
              <Checkbox
                checked={scheduleIds.includes(schedule.id)}
                onChange={(e) => {
                  const newScheduleIds = e.target.checked
                    ? [...scheduleIds, schedule.id]
                    : scheduleIds.filter((id) => id !== schedule.id);
                  setFieldValue("scheduleIds", newScheduleIds);
                }}
              />
            }
            label={`${schedule.startTime} - ${schedule.endTime}`}
          />
        ))}
      </Box>
    ));
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        clearAllStates();
        onClose();
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: isMobile ? "95%" : 600,
          maxHeight: "90vh",
          overflowY: "auto",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: isMobile ? 2 : 4,
          borderRadius: 2,
        }}
      >
        <Typography variant="h6" component="h2" gutterBottom>
          <FormattedMessage
            id="enrollmentModal.title"
            defaultMessage="Enroll Attendee"
          />
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, setFieldValue, isSubmitting }) => (
            <Form>
              <Grid container spacing={isMobile ? 1 : 2}>
                <Grid item xs={12}>
                  <Card>
                    <CardContent>
                      <Typography variant="subtitle1" gutterBottom>
                        <FormattedMessage
                          id="enrollmentModal.selectUser"
                          defaultMessage="Select User"
                        />
                      </Typography>
                      {!isNewUser ? (
                        <>
                          <Autocomplete
                            options={users}
                            getOptionLabel={(option) =>
                              `${option.firstname} ${option.lastname} (${option.email})`
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={intl.formatMessage({
                                  id: "enrollmentModal.selectUser",
                                  defaultMessage: "Select User",
                                })}
                                fullWidth
                              />
                            )}
                            onChange={(_, value) => {
                              setSelectedUser(value);
                              setFieldValue("user", value ? value.id : null);
                              if (value) {
                                setFieldValue("attendee", {
                                  firstName: value.firstname,
                                  lastName: value.lastname,
                                  email: value.email,
                                  phone: value.phone || "",
                                  birthdate: "",
                                  sex: "other",
                                });
                              }
                            }}
                            value={selectedUser}
                          />
                          <Button
                            onClick={() => setIsNewUser(true)}
                            sx={{ mt: 1 }}
                          >
                            <FormattedMessage
                              id="enrollmentModal.createNewUser"
                              defaultMessage="Create New User"
                            />
                          </Button>
                        </>
                      ) : (
                        <>
                          <Field
                            as={TextField}
                            name="user.email"
                            label={intl.formatMessage({ id: "signUp.email" })}
                            fullWidth
                            margin="normal"
                          />
                          <ErrorMessage
                            name="user.email"
                            component="div"
                            className="error"
                          />
                          <Field
                            as={TextField}
                            name="user.firstName"
                            label={intl.formatMessage({
                              id: "signUp.firstName",
                            })}
                            fullWidth
                            margin="normal"
                          />
                          <Field
                            as={TextField}
                            name="user.lastName"
                            label={intl.formatMessage({
                              id: "signUp.lastName",
                            })}
                            fullWidth
                            margin="normal"
                          />
                          <Field
                            as={TextField}
                            name="user.phone"
                            label={intl.formatMessage({ id: "signUp.phone" })}
                            fullWidth
                            margin="normal"
                          />
                          <Button
                            onClick={() => {
                              setIsNewUser(false);
                              setFieldValue("user", null);
                            }}
                            sx={{ mt: 1 }}
                          >
                            <FormattedMessage
                              id="enrollmentModal.selectExistingUser"
                              defaultMessage="Select Existing User"
                            />
                          </Button>
                        </>
                      )}
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card>
                    <CardContent>
                      <Typography variant="subtitle1" gutterBottom>
                        <FormattedMessage
                          id="enrollmentModal.selectAttendee"
                          defaultMessage="Select Attendee"
                        />
                      </Typography>
                      {!isNewAttendee ? (
                        <>
                          <Autocomplete
                            options={[
                              {
                                id: "userData",
                                firstName: "",
                                lastName: "",
                                email: "",
                              },
                              ...attendees,
                            ]}
                            getOptionLabel={(option) =>
                              option.id === "userData"
                                ? intl.formatMessage({
                                    id: "enrollmentModal.userData",
                                  })
                                : `${option.firstName} ${option.lastName} (${option.email})`
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={intl.formatMessage({
                                  id: "enrollmentModal.selectAttendee",
                                  defaultMessage: "Select Attendee",
                                })}
                                fullWidth
                              />
                            )}
                            onChange={(_, value) => {
                              if (value?.id === "userData") {
                                const userData = isNewUser
                                  ? values.user
                                  : users.find((u) => u.id === values.user) ||
                                    {};
                                setFieldValue("attendee", {
                                  firstName:
                                    (userData as any)?.firstname ||
                                    (userData as any)?.firstName ||
                                    "",
                                  lastName:
                                    (userData as any)?.lastname ||
                                    (userData as any)?.lastName ||
                                    "",
                                  email: (userData as any)?.email || "",
                                  phone: (userData as any)?.phone || "",
                                  birthdate: "",
                                  sex: "other",
                                });
                                setIsNewAttendee(true);
                              } else if (value) {
                                setSelectedAttendee(value as Attendee);
                                setFieldValue("attendee", value.id);
                                setIsNewAttendee(false);
                              } else {
                                setSelectedAttendee(null);
                                setFieldValue("attendee", null);
                                setIsNewAttendee(false);
                              }
                            }}
                            value={selectedAttendee}
                            disabled={!selectedUser && !isNewUser}
                          />
                          <Button
                            onClick={() => {
                              setIsNewAttendee(true);
                              setSelectedAttendee(null);
                              setFieldValue("attendee", {
                                firstName: "",
                                lastName: "",
                                email: "",
                                phone: "",
                                birthdate: "",
                                sex: "other",
                              });
                            }}
                            sx={{ mt: 1 }}
                          >
                            <FormattedMessage
                              id="enrollmentModal.createNewAttendee"
                              defaultMessage="Create New Attendee"
                            />
                          </Button>
                        </>
                      ) : (
                        <>
                          <Field
                            as={TextField}
                            name="attendee.firstName"
                            label={intl.formatMessage({
                              id: "signUp.firstName",
                            })}
                            fullWidth
                            margin="normal"
                          />
                          <ErrorMessage
                            name="attendee.firstName"
                            component="div"
                            className="error"
                          />
                          <Field
                            as={TextField}
                            name="attendee.lastName"
                            label={intl.formatMessage({
                              id: "signUp.lastName",
                            })}
                            fullWidth
                            margin="normal"
                          />
                          <ErrorMessage
                            name="attendee.lastName"
                            component="div"
                            className="error"
                          />
                          <Field
                            as={TextField}
                            name="attendee.birthdate"
                            label={intl.formatMessage({
                              id: "admin.dashboard.birthdate",
                            })}
                            type="date"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{ shrink: true }}
                          />
                          <ErrorMessage
                            name="attendee.birthdate"
                            component="div"
                            className="error"
                          />
                          <Field as={RadioGroup} name="attendee.sex" row>
                            <FormControlLabel
                              value="female"
                              control={<Radio />}
                              label={intl.formatMessage({
                                id: "privateEnroll.female",
                              })}
                            />
                            <FormControlLabel
                              value="male"
                              control={<Radio />}
                              label={intl.formatMessage({
                                id: "privateEnroll.male",
                              })}
                            />
                            <FormControlLabel
                              value="other"
                              control={<Radio />}
                              label={intl.formatMessage({
                                id: "privateEnroll.other",
                              })}
                            />
                          </Field>
                          <ErrorMessage
                            name="attendee.sex"
                            component="div"
                            className="error"
                          />
                          <Field
                            as={TextField}
                            name="attendee.phone"
                            label={intl.formatMessage({ id: "signUp.phone" })}
                            fullWidth
                            margin="normal"
                          />
                          <ErrorMessage
                            name="attendee.phone"
                            component="div"
                            className="error"
                          />
                          <Field
                            as={TextField}
                            name="attendee.email"
                            label={intl.formatMessage({ id: "signUp.email" })}
                            fullWidth
                            margin="normal"
                          />
                          <ErrorMessage
                            name="attendee.email"
                            component="div"
                            className="error"
                          />
                          <Button
                            onClick={() => {
                              setIsNewAttendee(false);
                              setSelectedAttendee(null);
                              setFieldValue("attendee", null);
                            }}
                            sx={{ mt: 1 }}
                          >
                            <FormattedMessage
                              id="enrollmentModal.selectExistingAttendee"
                              defaultMessage="Select Existing Attendee"
                            />
                          </Button>
                        </>
                      )}
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card>
                    <CardContent>
                      <Typography variant="subtitle1" gutterBottom>
                        <FormattedMessage
                          id="enrollmentModal.selectSchedules"
                          defaultMessage="Select Schedules"
                        />
                      </Typography>
                      {renderSchedules(values.scheduleIds, setFieldValue)}
                      <ErrorMessage
                        name="scheduleIds"
                        component="div"
                        className="error"
                      />
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
              {backendError && (
                <Alert severity="error" sx={{ mt: 2 }}>
                  {backendError}
                </Alert>
              )}
              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{ mb: isMobile ? 1 : 0, mr: isMobile ? 0 : 1 }}
                  fullWidth={isMobile}
                  disabled={isSubmitting}
                >
                  <FormattedMessage
                    id="enrollmentModal.enroll"
                    defaultMessage="Enroll"
                  />
                </Button>
                <Button
                  onClick={() => {
                    clearAllStates();
                    onClose();
                  }}
                  variant="outlined"
                  fullWidth={isMobile}
                >
                  <FormattedMessage
                    id="common.cancel"
                    defaultMessage="Cancel"
                  />
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default EnrollmentModal;
