import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Typography,
  Box,
  Button,
  TextField,
  List,
  ListItem,
  ListItemText,
  Paper,
  Container,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Checkbox,
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { getProfile, logOut } from "../../redux/auth/authOperations";
import {
  createAttendee,
  createAttendeeToSchedule,
  fetchClassPublic,
} from "../../redux/enrollment/enrollmentOperations";
import { setFrom } from "../../redux/auth/authSlice";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useLanguage } from "../../components/LanguageProvider/LanguageProvider";
import LanguageSelector from "../../components/LanguageSelector/LanguageSelector";
import { Attendee, Schedule } from "../../redux/types/types";
import Notiflix from "notiflix";

const getLocalizedValue = (
  obj: Record<string, string>,
  language: string
): string => {
  if (obj[language]) {
    return obj[language];
  }
  return obj["en"] || "";
};

const PrivateEnrollPage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const intl = useIntl();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const classId = searchParams.get("classID");
  const [self, setSelf] = useState<boolean | null>(null);
  const [selectedSchedules, setSelectedSchedules] = useState<number[]>([]);
  const [selectedAttendee, setSelectedAttendee] = useState<Attendee | null>(
    null
  );
  const { language, setLanguage } = useLanguage();

  const { user } = useSelector((state: RootState) => state.auth);
  const classData = useSelector((state: RootState) => state.enrollment.class);

  useEffect(() => {
    const langParam = searchParams.get("Lang");
    if (langParam && ["en", "fr", "uk"].includes(langParam.toLowerCase())) {
      setLanguage(langParam.toLowerCase() as "en" | "fr" | "uk");
    }

    dispatch(setFrom(null));
    if (classId) {
      dispatch(fetchClassPublic(Number(classId)));
    }
  }, [dispatch, classId, searchParams, setLanguage]);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(
      intl.formatMessage({ id: "signUp.firstNameRequired" })
    ),
    lastName: Yup.string().required(
      intl.formatMessage({ id: "signUp.lastNameRequired" })
    ),
    birthdate: Yup.date().required(
      intl.formatMessage({ id: "privateEnroll.birthdateRequired" })
    ),
    sex: Yup.string().required(
      intl.formatMessage({ id: "privateEnroll.sexRequired" })
    ),
    phone: Yup.string().required(
      intl.formatMessage({ id: "signUp.phoneRequired" })
    ),
    email: Yup.string()
      .email(intl.formatMessage({ id: "signUp.invalidEmail" }))
      .required(intl.formatMessage({ id: "signUp.emailRequired" })),
  });

  const handleLogout = () => {
    dispatch(logOut());
  };

  const handleScheduleChange = (scheduleId: number) => {
    setSelectedSchedules((prev) =>
      prev.includes(scheduleId)
        ? prev.filter((id) => id !== scheduleId)
        : [...prev, scheduleId]
    );
  };

  const renderSchedules = () => {
    if (!classData || !classData.schedules) return null;

    const weekdays = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    const groupedSchedules: { [key: string]: Schedule[] } = {};

    classData.schedules.forEach((schedule) => {
      const day =
        weekdays[schedule.dayOfWeek === 0 ? 6 : schedule.dayOfWeek - 1];
      if (!groupedSchedules[day]) {
        groupedSchedules[day] = [];
      }
      groupedSchedules[day].push(schedule);
    });

    return weekdays
      .filter(
        (day) => groupedSchedules[day] && groupedSchedules[day].length > 0
      )
      .map((day) => (
        <Box key={day} sx={{ mb: 2 }}>
          <Typography variant="h6">{day}</Typography>
          {groupedSchedules[day].map((schedule) => (
            <FormControlLabel
              key={schedule.id}
              control={
                <Checkbox
                  checked={selectedSchedules.includes(schedule.id)}
                  onChange={() => handleScheduleChange(schedule.id)}
                />
              }
              label={`${schedule.startTime} - ${schedule.endTime}`}
            />
          ))}
        </Box>
      ));
  };

  const handleCreateAttendee = async (
    values: Omit<Attendee, "id" | "userId">
  ) => {
    const resultAction = await dispatch(
      createAttendee({
        ...values,
        birthdate: new Date(values.birthdate),
        isUser: Boolean(self),
      })
    );
    if (createAttendee.fulfilled.match(resultAction)) {
      setSelectedAttendee(resultAction.payload);
    }
  };

  const handleEnroll = async () => {
    if (!selectedAttendee || !classId) return;

    try {
      for (const scheduleId of selectedSchedules) {
        await dispatch(
          createAttendeeToSchedule({
            attendeeId: selectedAttendee.id,
            scheduleId,
          })
        );
      }

      Notiflix.Notify.success(
        intl.formatMessage({ id: "privateEnroll.successfulRegistration" })
      );
      await dispatch(getProfile());
      navigate("/my-classes");
    } catch (error) {
      Notiflix.Notify.failure(
        intl.formatMessage({ id: "privateEnroll.enrollmentError" })
      );
    }
  };

  const handleSelectExistingAttendee = (attendee: Attendee) => {
    setSelectedAttendee(attendee);
  };

  const handleWho = (bool: boolean) => {
    let selfAtt;
    if (bool) {
      selfAtt = user.attendees.find((a) => a.isUser);
    }
    setSelf(bool);
    setSelectedAttendee(selfAtt?.id ? selfAtt : null);
    setSelectedSchedules([]);
  };

  return (
    <Container maxWidth="md" sx={{ pb: 5 }}>
      <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
          <LanguageSelector />
        </Box>
        <Typography variant="h4" gutterBottom align="center">
          <FormattedMessage
            id="privateEnroll.title"
            defaultMessage="Enroll Details"
          />
        </Typography>

        {classId && (
          <Typography variant="body1" gutterBottom align="center">
            <FormattedMessage
              id="privateEnroll.enrollingFor"
              defaultMessage="Enrolling for {className} class"
              values={{
                className: classData?.title
                  ? getLocalizedValue(classData.title, language)
                  : "",
              }}
            />
          </Typography>
        )}

        {self === null && (
          <Box sx={{ mt: 4 }}>
            <Typography variant="h5" gutterBottom align="center">
              <FormattedMessage
                id="privateEnroll.enrollingWho"
                defaultMessage="Who do you want to enroll?"
              />
            </Typography>

            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} sm={6}>
                <Button
                  onClick={() => handleWho(true)}
                  fullWidth
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  <FormattedMessage
                    id="privateEnroll.self"
                    defaultMessage="SELF"
                  />
                </Button>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  onClick={() => handleWho(false)}
                  fullWidth
                  variant="contained"
                  color="secondary"
                  size="large"
                >
                  <FormattedMessage
                    id="privateEnroll.anotherPerson"
                    defaultMessage="ANOTHER PERSON"
                  />
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}

        {self !== null && (
          <Box sx={{ mt: 4 }}>
            {Boolean(user.attendees.length) && self === false && (
              <Box sx={{ mb: 4 }}>
                <Typography variant="h5" gutterBottom align="center">
                  <FormattedMessage
                    id="privateEnroll.existingAttendees"
                    defaultMessage="Existing Attendees"
                  />
                </Typography>

                <List>
                  {user.attendees.map((attendee) => (
                    <ListItem
                      key={attendee.id}
                      sx={{
                        flexDirection: "column",
                        alignItems: "flex-start",
                        border: "1px solid #e0e0e0",
                        borderRadius: 2,
                        mb: 2,
                        p: 2,
                      }}
                    >
                      <ListItemText
                        primary={`${attendee.firstName} ${attendee.lastName}`}
                        secondary={
                          <FormattedMessage
                            id="privateEnroll.attendeeInfo"
                            defaultMessage="{email}: {emailValue} | {phone}: {phoneValue}"
                            values={{
                              email: intl.formatMessage({
                                id: "signIn.emailLabel",
                              }),
                              emailValue: attendee.email,
                              phone: intl.formatMessage({
                                id: "signUp.phone",
                              }),
                              phoneValue: attendee.phone,
                            }}
                          />
                        }
                      />
                      <Button
                        onClick={() => handleSelectExistingAttendee(attendee)}
                        variant="contained"
                        color="primary"
                        sx={{ mt: 1 }}
                      >
                        <FormattedMessage
                          id="privateEnroll.select"
                          defaultMessage="Select"
                        />
                      </Button>
                    </ListItem>
                  ))}
                </List>
              </Box>
            )}

            {!selectedAttendee && (
              <Box>
                <Typography variant="h5" gutterBottom align="center">
                  <FormattedMessage
                    id="privateEnroll.createNewAttendee"
                    defaultMessage={
                      self ? "Enroll Yourself" : "Create New Attendee"
                    }
                  />
                </Typography>
                <Formik
                  initialValues={{
                    firstName: self && user.firstname ? user.firstname : "",
                    lastName: self && user.lastname ? user.lastname : "",
                    birthdate: new Date(),
                    sex: "",
                    phone: self && user.phone ? user.phone : "",
                    email: self && user.email ? user.email : "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={handleCreateAttendee}
                >
                  {({ errors, touched }) => (
                    <Form>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <Field
                            as={TextField}
                            name="firstName"
                            label={intl.formatMessage({
                              id: "signUp.firstName",
                            })}
                            error={touched.firstName && errors.firstName}
                            helperText={touched.firstName && errors.firstName}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Field
                            as={TextField}
                            name="lastName"
                            label={intl.formatMessage({
                              id: "signUp.lastName",
                            })}
                            error={touched.lastName && errors.lastName}
                            helperText={touched.lastName && errors.lastName}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            as={TextField}
                            name="birthdate"
                            label={intl.formatMessage({
                              id: "admin.dashboard.birthdate",
                            })}
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            error={touched.birthdate && errors.birthdate}
                            helperText={touched.birthdate && errors.birthdate}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl component="fieldset">
                            <FormLabel component="legend">
                              {intl.formatMessage({
                                id: "admin.dashboard.sex",
                              })}
                            </FormLabel>
                            <Field
                              as={RadioGroup}
                              name="sex"
                              row
                              error={touched.sex && errors.sex}
                            >
                              <FormControlLabel
                                value="female"
                                control={<Radio />}
                                label={intl.formatMessage({
                                  id: "privateEnroll.female",
                                })}
                              />
                              <FormControlLabel
                                value="male"
                                control={<Radio />}
                                label={intl.formatMessage({
                                  id: "privateEnroll.male",
                                })}
                              />
                              <FormControlLabel
                                value="other"
                                control={<Radio />}
                                label={intl.formatMessage({
                                  id: "privateEnroll.other",
                                })}
                              />
                            </Field>
                            <ErrorMessage name="sex">
                              {(msg) => (
                                <Typography
                                  color="error"
                                  variant="caption"
                                  component="div"
                                  style={{ marginTop: 4 }}
                                >
                                  {msg}
                                </Typography>
                              )}
                            </ErrorMessage>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Field
                            as={TextField}
                            name="phone"
                            label={intl.formatMessage({ id: "signUp.phone" })}
                            error={touched.phone && errors.phone}
                            helperText={touched.phone && errors.phone}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Field
                            as={TextField}
                            name="email"
                            label={intl.formatMessage({ id: "signUp.email" })}
                            type="email"
                            error={touched.email && errors.email}
                            helperText={touched.email && errors.email}
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                            size="large"
                          >
                            <FormattedMessage
                              id="privateEnroll.createAttendee"
                              defaultMessage="Create Attendee"
                            />
                          </Button>
                        </Grid>
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </Box>
            )}

            {selectedAttendee && (
              <Box sx={{ mt: 4 }}>
                <Typography variant="h6" gutterBottom>
                  <FormattedMessage
                    id="privateEnroll.selectedAttendee"
                    defaultMessage="Selected Attendee: {name}"
                    values={{
                      name: `${selectedAttendee.firstName} ${selectedAttendee.lastName}`,
                    }}
                  />
                </Typography>

                <Typography variant="h6" gutterBottom>
                  <FormattedMessage
                    id="privateEnroll.selectSchedules"
                    defaultMessage="Select Schedules"
                  />
                </Typography>
                {renderSchedules()}

                <Button
                  onClick={handleEnroll}
                  variant="contained"
                  color="primary"
                  fullWidth
                  size="large"
                  disabled={selectedSchedules.length === 0}
                  sx={{ mt: 2 }}
                >
                  <FormattedMessage
                    id="privateEnroll.enroll"
                    defaultMessage="Enroll"
                  />
                </Button>
              </Box>
            )}
          </Box>
        )}

        <Box sx={{ mt: 4 }}>
          <Button
            onClick={handleLogout}
            fullWidth
            variant="outlined"
            color="error"
            size="large"
          >
            <FormattedMessage id="common.cancel" defaultMessage="CANCEL" />
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default PrivateEnrollPage;
