import { createAsyncThunk } from "@reduxjs/toolkit";
import Notiflix from "notiflix";
import Cookies from "js-cookie";

import {
  appleAuthApi,
  getProfileApi,
  googleAuthApi,
  loginAdminApi,
  loginUserApi,
  registerUserApi,
  updateProfileApi,
  loginInstructorApi,
  changePasswordApi,
} from "../../utils/api/auth";

const handleError = (error: any): string => {
  if (error.response && error.response.data && error.response.data.message) {
    return error.response.data.message;
  } else if (error.message) {
    return error.message;
  } else {
    return "An unexpected error occurred";
  }
};

export const registerUser = createAsyncThunk<
  void,
  {
    email: string;
    password: string;
    firstname: string;
    lastname: string;
    phone: string;
  },
  { rejectValue: string }
>(
  "auth/register",
  async (
    { email, password, firstname, lastname, phone },
    { rejectWithValue }
  ) => {
    try {
      const response = await registerUserApi({
        email,
        password,
        firstname,
        lastname,
        phone,
      });

      const token = response.access_token;
      Cookies.set("authToken", token);

      Notiflix.Notify.success("Registration successful!");
    } catch (error: any) {
      const errorMessage = handleError(error);
      Notiflix.Notify.failure(`Registration failed: ${errorMessage}`);
      return rejectWithValue(errorMessage);
    }
  }
);

export const loginUser = createAsyncThunk<
  void,
  { email: string; password: string; remember: boolean },
  { rejectValue: string }
>("auth/login", async ({ email, password, remember }, { rejectWithValue }) => {
  try {
    const response = await loginUserApi({ email, password });
    const token = response.access_token;

    const cookieOptions = remember ? { expires: 30 } : undefined;
    Cookies.set("authToken", token, cookieOptions);

    Notiflix.Notify.success("Login successful!");
  } catch (error: any) {
    const errorMessage = handleError(error);
    Notiflix.Notify.failure(`Login failed: ${errorMessage}`);
    return rejectWithValue(errorMessage);
  }
});

export const getProfile = createAsyncThunk<
  any,
  undefined,
  { rejectValue: string }
>("auth/profile", async (_, { rejectWithValue }) => {
  try {
    const response = await getProfileApi();
    return response;
  } catch (error: any) {
    const errorMessage = handleError(error);
    Notiflix.Notify.failure(`Fetching profile failed: ${errorMessage}`);
    return rejectWithValue(errorMessage);
  }
});

export const updateProfile = createAsyncThunk<
  void,
  { firstname: string; lastname: string; email: string; phone: string },
  { rejectValue: string }
>("auth/updateProfile", async (profileData, { rejectWithValue }) => {
  try {
    await updateProfileApi(profileData);
    Notiflix.Notify.success("Profile updated successfully!");
  } catch (error: any) {
    const errorMessage = handleError(error);
    Notiflix.Notify.failure(`Profile update failed: ${errorMessage}`);
    return rejectWithValue(errorMessage);
  }
});

export const googleAuth = createAsyncThunk<
  void,
  { token: string },
  { rejectValue: string }
>("auth/google", async ({ token }, { rejectWithValue }) => {
  try {
    const response = await googleAuthApi(token);
    const authToken = response.access_token;

    Cookies.set("authToken", authToken);

    Notiflix.Notify.success("Google authentication successful!");
  } catch (error: any) {
    const errorMessage = handleError(error);
    Notiflix.Notify.failure(`Google authentication failed: ${errorMessage}`);
    return rejectWithValue(errorMessage);
  }
});

export const appleAuth = createAsyncThunk<
  void,
  { body: any },
  { rejectValue: string }
>("auth/apple", async ({ body }, { rejectWithValue }) => {
  try {
    const response = await appleAuthApi(body);
    const authToken = response.access_token;

    Cookies.set("authToken", authToken);

    Notiflix.Notify.success("Apple authentication successful!");
  } catch (error: any) {
    const errorMessage = handleError(error);
    Notiflix.Notify.failure(`Apple authentication failed: ${errorMessage}`);
    return rejectWithValue(errorMessage);
  }
});

export const logOut = createAsyncThunk("auth/logout", async () => {
  Cookies.remove("authToken");
});

export const loginAdmin = createAsyncThunk<
  void,
  { email: string; password: string; remember: boolean },
  { rejectValue: string }
>(
  "auth/loginAdmin",
  async ({ email, password, remember }, { rejectWithValue }) => {
    try {
      const response = await loginAdminApi({ email, password });
      const token = response.access_token;

      Cookies.set("authToken", token);

      return;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || "Login failed");
    }
  }
);

export const loginInstructor = createAsyncThunk<
  void,
  { email: string; password: string; remember: boolean },
  { rejectValue: string }
>(
  "auth/loginInstructor",
  async ({ email, password, remember }, { rejectWithValue }) => {
    try {
      const response = await loginInstructorApi({ email, password });
      const token = response.access_token;

      Cookies.set("authToken", token);

      Notiflix.Notify.success("Instructor login successful!");
    } catch (error: any) {
      const errorMessage = handleError(error);
      Notiflix.Notify.failure(`Instructor login failed: ${errorMessage}`);
      return rejectWithValue(errorMessage);
    }
  }
);

export const changePassword = createAsyncThunk<
  void,
  { oldPassword: string; newPassword: string },
  { rejectValue: string }
>(
  "auth/changePassword",
  async ({ oldPassword, newPassword }, { rejectWithValue }) => {
    try {
      await changePasswordApi(oldPassword, newPassword);
      Notiflix.Notify.success("Password changed successfully!");
    } catch (error: any) {
      const errorMessage = handleError(error);
      Notiflix.Notify.failure(`Password change failed: ${errorMessage}`);
      return rejectWithValue(errorMessage);
    }
  }
);
