import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Modal,
  Box,
  TextField,
  Button,
  Typography,
  IconButton,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import { changePassword } from "../../../redux/auth/authOperations";
import { FormattedMessage, useIntl } from "react-intl";
import CloseIcon from "@mui/icons-material/Close";

interface PasswordChangeModalProps {
  open: boolean;
  onClose: () => void;
}

export default function PasswordChangeModal({
  open,
  onClose,
}: PasswordChangeModalProps) {
  const dispatch = useDispatch<AppDispatch>();
  const intl = useIntl();

  const PasswordChangeSchema = Yup.object().shape({
    oldPassword: Yup.string().required(
      intl.formatMessage({ id: "resetPassword.passwordRequired" })
    ),
    newPassword: Yup.string()
      .min(8, intl.formatMessage({ id: "resetPassword.passwordMinLength" }))
      .required(intl.formatMessage({ id: "resetPassword.passwordRequired" })),
    confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref("newPassword")],
        intl.formatMessage({ id: "resetPassword.passwordsMustMatch" })
      )
      .required(
        intl.formatMessage({ id: "resetPassword.confirmPasswordRequired" })
      ),
  });

  const handleSubmit = async (
    values: { oldPassword: string; newPassword: string },
    { setSubmitting }: any
  ) => {
    try {
      await dispatch(changePassword(values)).unwrap();
      onClose();
    } catch (error) {
      console.error("Failed to change password:", error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "90%", sm: 400 },
          maxWidth: "100%",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          maxHeight: "90vh",
          overflow: "auto",
        }}
      >
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" component="h2" gutterBottom>
          <FormattedMessage id="profile.changePassword" />
        </Typography>
        <Formik
          initialValues={{
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
          }}
          validationSchema={PasswordChangeSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, isSubmitting }) => (
            <Form>
              <Field
                as={TextField}
                margin="normal"
                required
                fullWidth
                name="oldPassword"
                label={intl.formatMessage({ id: "profile.oldPassword" })}
                type="password"
                error={touched.oldPassword && !!errors.oldPassword}
                helperText={touched.oldPassword && errors.oldPassword}
              />
              <Field
                as={TextField}
                margin="normal"
                required
                fullWidth
                name="newPassword"
                label={intl.formatMessage({ id: "profile.newPassword" })}
                type="password"
                error={touched.newPassword && !!errors.newPassword}
                helperText={touched.newPassword && errors.newPassword}
              />
              <Field
                as={TextField}
                margin="normal"
                required
                fullWidth
                name="confirmPassword"
                label={intl.formatMessage({ id: "profile.confirmPassword" })}
                type="password"
                error={touched.confirmPassword && !!errors.confirmPassword}
                helperText={touched.confirmPassword && errors.confirmPassword}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: { xs: 2, sm: 0 } }}
                disabled={isSubmitting}
              >
                <FormattedMessage id="profile.changePassword" />
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
}
