import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Box,
  Typography,
  useMediaQuery,
  useTheme,
  List,
  ListItem,
  ListItemText,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Stack,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import allLocales from "@fullcalendar/core/locales-all";
import { EventInput, EventContentArg, EventClickArg } from "@fullcalendar/core";
import { RootState } from "../../redux/store";
import { Class, Schedule, Attendee } from "../../redux/types/types";
import { useLanguage } from "../../components/LanguageProvider/LanguageProvider";

interface ExtendedEventInput extends EventInput {
  extendedProps: {
    classId: number;
    location: string;
    type: string;
    attendees: Attendee[];
  };
}

export default function MySchedules() {
  const intl = useIntl();
  const user = useSelector((state: RootState) => state.auth.user);
  const isInstructor = useSelector(
    (state: RootState) => state.auth.isInstructor
  );
  const { language } = useLanguage();
  const [events, setEvents] = useState<ExtendedEventInput[]>([]);
  const [selectedEvent, setSelectedEvent] = useState<ExtendedEventInput | null>(
    null
  );
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const getLocalizedValue = (
    obj: Record<string, string>,
    lang: string
  ): string => {
    return obj[lang] || obj["en"] || "";
  };

  const generateColor = (id: number): string => {
    const hue = (id * 137.508) % 360;
    return theme.palette.mode === "dark"
      ? `hsl(${hue}, 70%, 35%)`
      : `hsl(${hue}, 70%, 65%)`;
  };

  const generateAttendeeColor = (id: number): string => {
    const hue = (id * 137.508) % 360;
    return theme.palette.mode === "dark"
      ? `hsl(${hue}, 100%, 25%)`
      : `hsl(${hue}, 100%, 75%)`;
  };

  useEffect(() => {
    const newEvents: ExtendedEventInput[] = user.classes.flatMap(
      (classItem: Class) => {
        return classItem.schedules.map((schedule: Schedule) => ({
          id: `${classItem.id}-${schedule.id}`,
          title: getLocalizedValue(classItem.title, language),
          daysOfWeek: [(schedule.dayOfWeek + 1) % 7],
          startTime: schedule.startTime,
          endTime: schedule.endTime,
          startStr: `2023-01-01T${schedule.startTime}:00`,
          endStr: `2023-01-01T${schedule.endTime}:00`,
          color: generateColor(classItem.id),
          extendedProps: {
            location: getLocalizedValue(classItem.location.name, language),
            type: getLocalizedValue(classItem.type.name, language),
            classId: classItem.id,
            attendees: schedule.attendees || [],
          },
        }));
      }
    );
    setEvents(newEvents);
    //eslint-disable-next-line
  }, [user.classes, language, theme.palette.mode]);

  const renderEventContent = (eventInfo: EventContentArg) => {
    const view = eventInfo.view.type;
    const isMonthView = view === "dayGridMonth";

    return (
      <Box
        sx={{
          fontSize: isMonthView ? "0.7rem" : "0.8rem",
          lineHeight: 1.2,
          padding: "2px 4px",
          borderRadius: "4px",
          backgroundColor: eventInfo.backgroundColor,
          color: theme.palette.getContrastText(
            eventInfo.backgroundColor as string
          ),
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          overflow: "hidden",
        }}
      >
        <Typography variant="caption" sx={{ fontWeight: "bold" }}>
          {isMonthView
            ? `${eventInfo.event.startStr.slice(
                11,
                16
              )} - ${eventInfo.event.endStr.slice(11, 16)}`
            : eventInfo.timeText}
        </Typography>
        <Typography variant="caption" sx={{ fontWeight: "bold" }}>
          {eventInfo.event.title}
        </Typography>
        {!isMonthView && (
          <>
            <Typography variant="caption" sx={{ display: "block", mt: 0.5 }}>
              {eventInfo.event.extendedProps.location}
            </Typography>
            <Typography variant="caption" sx={{ display: "block" }}>
              {eventInfo.event.extendedProps.type}
            </Typography>
          </>
        )}
        {!isInstructor && (
          <Stack direction="row" spacing={0.5} mt={0.5}>
            {eventInfo.event.extendedProps.attendees
              .slice(0, 5)
              .map((attendee: Attendee) => (
                <Box
                  key={attendee.id}
                  sx={{
                    width: 8,
                    height: 8,
                    borderRadius: "50%",
                    backgroundColor: generateAttendeeColor(attendee.id),
                  }}
                />
              ))}
            {eventInfo.event.extendedProps.attendees.length > 5 && (
              <Typography variant="caption" sx={{ fontSize: "0.6rem" }}>
                +{eventInfo.event.extendedProps.attendees.length - 5}
              </Typography>
            )}
          </Stack>
        )}
      </Box>
    );
  };

  const handleEventClick = (clickInfo: EventClickArg) => {
    setSelectedEvent(clickInfo.event.toPlainObject() as ExtendedEventInput);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <Box sx={{ height: "100%", p: isMobile ? 1 : 3 }}>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
        initialView={isMobile ? "listWeek" : "dayGridMonth"}
        headerToolbar={{
          left: isMobile ? "prev,next" : "prev,next today",
          center: "title",
          right: isMobile
            ? "listWeek,timeGridDay"
            : "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
        }}
        locale={language}
        locales={allLocales}
        buttonText={{
          today: intl.formatMessage({
            id: "calendar.today",
            defaultMessage: "Today",
          }),
          month: intl.formatMessage({
            id: "calendar.month",
            defaultMessage: "Month",
          }),
          week: intl.formatMessage({
            id: "calendar.week",
            defaultMessage: "Week",
          }),
          day: intl.formatMessage({
            id: "calendar.day",
            defaultMessage: "Day",
          }),
          list: intl.formatMessage({
            id: "calendar.list",
            defaultMessage: "List",
          }),
        }}
        titleFormat={(args) => {
          const date = new Date(args.date.year, args.date.month, args.date.day);
          return intl.formatDate(date, { year: "numeric", month: "long" });
        }}
        events={events}
        eventContent={renderEventContent}
        eventClick={handleEventClick}
        height="auto"
        aspectRatio={isMobile ? 0.8 : 1.35}
        firstDay={1}
        stickyHeaderDates={true}
        eventDisplay="block"
        slotLabelFormat={{
          hour: "numeric",
          minute: "2-digit",
          omitZeroMinute: true,
          meridiem: "short",
        }}
      />
      <Dialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          {selectedEvent?.title}
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {selectedEvent && (
            <>
              <Typography variant="body1">
                <FormattedMessage
                  id="mySchedules.location"
                  defaultMessage="Location"
                />
                : {selectedEvent.extendedProps.location}
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                <FormattedMessage id="mySchedules.type" defaultMessage="Type" />
                : {selectedEvent.extendedProps.type}
              </Typography>
              <Typography variant="h6" sx={{ mb: 1 }}>
                <FormattedMessage
                  id="mySchedules.attendees"
                  defaultMessage="Attendees"
                />
                :
              </Typography>
              <List>
                {selectedEvent.extendedProps.attendees.map(
                  (attendee: Attendee) => (
                    <ListItem key={attendee.id}>
                      <Avatar
                        sx={{
                          bgcolor: generateAttendeeColor(attendee.id),
                          mr: 2,
                        }}
                      >
                        {attendee.firstName[0]}
                      </Avatar>
                      <ListItemText
                        primary={`${attendee.firstName} ${attendee.lastName}`}
                        secondary={attendee.email}
                      />
                    </ListItem>
                  )
                )}
              </List>
            </>
          )}
        </DialogContent>
      </Dialog>
      <style>
        {`
          .fc .fc-toolbar-title {
            color: ${theme.palette.text.primary};
          }
          .fc .fc-button {
            background-color: ${theme.palette.primary.main};
            border-color: ${theme.palette.primary.main};
            color: ${theme.palette.primary.contrastText};
          }
          .fc .fc-button:hover {
            background-color: ${theme.palette.primary.dark};
            border-color: ${theme.palette.primary.dark};
          }
          .fc .fc-button-primary:not(:disabled).fc-button-active, 
          .fc .fc-button-primary:not(:disabled):active {
            background-color: ${theme.palette.primary.dark};
            border-color: ${theme.palette.primary.dark};
          }
          .fc-theme-standard .fc-scrollgrid,
          .fc-theme-standard td,
          .fc-theme-standard th {
            border-color: ${theme.palette.divider};
          }
          .fc .fc-daygrid-day.fc-day-today,
          .fc .fc-col-header-cell.fc-day-today {
            background-color: ${theme.palette.action.selected};
          }
          .fc .fc-col-header-cell-cushion,
          .fc .fc-daygrid-day-number,
          .fc .fc-list-event-time,
          .fc .fc-list-event-title {
            color: ${theme.palette.text.primary};
          }
          .fc-theme-standard .fc-list-day-cushion {
            background-color: ${theme.palette.background.paper};
          }
          .fc .fc-list-event:hover td {
            background-color: ${theme.palette.action.hover};
          }
          .fc .fc-list-empty {
            background-color: ${theme.palette.background.paper};
            color: ${theme.palette.text.secondary};
          }
          .fc-day-today .fc-daygrid-day-number {
            font-weight: bold;
          }
          .fc .fc-col-header-cell-cushion,
          .fc .fc-timegrid-axis-cushion,
          .fc .fc-timegrid-slot-label-cushion {
            color: ${theme.palette.text.primary};
          }
          .fc .fc-col-header-cell {
            background-color: ${theme.palette.background.paper};
          }
          .fc .fc-daygrid-day {
            background-color: ${theme.palette.background.default};
          }
          .fc .fc-daygrid-day-top {
            padding: 4px;
          }
          .fc .fc-daygrid-day-number {
            color: ${theme.palette.text.primary};
            font-weight: bold;
          }
          .fc .fc-day-today {
            background-color: ${theme.palette.action.selected} !important;
          }
          .fc-event-title,
          .fc-event-time {
            padding: 1px 2px;
            font-weight: bold;
          }
          .fc .fc-timegrid-col.fc-day-today,
          .fc .fc-timegrid-col.fc-day-today .fc-timegrid-col-frame {
            background-color: ${theme.palette.action.selected} !important;
          }
          .fc .fc-timegrid-now-indicator-line {
            border-color: ${theme.palette.error.main};
          }
          .fc .fc-timegrid-now-indicator-arrow {
            border-color: ${theme.palette.error.main};
            color: ${theme.palette.error.main};
          }
          .fc .fc-col-header-cell.fc-day.fc-day-today {
            background-color: ${theme.palette.primary.main} !important;
          }
          .fc .fc-col-header-cell.fc-day.fc-day-today .fc-col-header-cell-cushion {
            color: ${theme.palette.primary.contrastText} !important;
          }
          .fc .fc-timegrid-axis {
            background-color: ${
              theme.palette.mode === "dark"
                ? theme.palette.grey[800]
                : theme.palette.background.paper
            };
          }
        `}
      </style>
    </Box>
  );
}
