import React from "react";
import { useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Box,
  Typography,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  Grid,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { RootState } from "../../redux/store";
import { Class, Schedule, Attendee } from "../../redux/types/types";
import { useLanguage } from "../LanguageProvider/LanguageProvider";

export default function UpcomingClasses() {
  const intl = useIntl();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const user = useSelector((state: RootState) => state.auth.user);
  const { language } = useLanguage();

  const getNextSchedule = (schedules: Schedule[]): Schedule | null => {
    const now = new Date();
    const currentDay = now.getDay() === 0 ? 6 : now.getDay() - 1;
    const currentTime = now.getHours() * 60 + now.getMinutes();

    return (
      schedules
        .filter((schedule) => {
          const scheduleDay = schedule.dayOfWeek;
          const [startHour, startMinute] = schedule.startTime
            .split(":")
            .map(Number);
          const scheduleTime = startHour * 60 + startMinute;

          return (
            scheduleDay > currentDay ||
            (scheduleDay === currentDay && scheduleTime > currentTime)
          );
        })
        .sort((a, b) => {
          const dayDiff = a.dayOfWeek - b.dayOfWeek;
          if (dayDiff !== 0) return dayDiff;
          return (
            parseInt(a.startTime.replace(":", "")) -
            parseInt(b.startTime.replace(":", ""))
          );
        })[0] || null
    );
  };

  const formatDay = (day: number): string => {
    const days = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    return intl.formatMessage({
      id: `days.${days[day].toLowerCase()}`,
      defaultMessage: days[day],
    });
  };

  const upcomingClasses = user.classes
    .map((classItem: Class) => ({
      ...classItem,
      nextSchedule: getNextSchedule(classItem.schedules),
    }))
    .filter((classItem) => classItem.nextSchedule)
    .sort((a, b) => {
      if (!a.nextSchedule || !b.nextSchedule) return 0;
      const dayDiff = a.nextSchedule.dayOfWeek - b.nextSchedule.dayOfWeek;
      if (dayDiff !== 0) return dayDiff;
      return (
        parseInt(a.nextSchedule.startTime.replace(":", "")) -
        parseInt(b.nextSchedule.startTime.replace(":", ""))
      );
    });

  const getLocalizedValue = (
    obj: Record<string, string>,
    lang: string
  ): string => {
    return obj[lang] || obj["en"] || "";
  };

  const formatClassTime = (schedule: Schedule): string => {
    const today = new Date().getDay() === 0 ? 6 : new Date().getDay() - 1;
    if (schedule.dayOfWeek === today) {
      return intl.formatMessage(
        { id: "upcomingClasses.todayAt", defaultMessage: "Today at {time}" },
        { time: schedule.startTime }
      );
    } else {
      return `${formatDay(schedule.dayOfWeek)}, ${schedule.startTime}`;
    }
  };

  const calculateAge = (birthdate: Date): number => {
    const today = new Date();
    const birthDate = new Date(birthdate);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const renderAttendees = (classItem: Class) => {
    return (
      <Box sx={{ mt: 2 }}>
        <Typography variant="subtitle2">
          <FormattedMessage
            id="upcomingClasses.attendees"
            defaultMessage="Attendees"
          />
          :
        </Typography>
        <List sx={{ p: 0 }}>
          {classItem.attendees?.map((attendee: Attendee, index: number) => (
            <ListItem key={attendee.id} sx={{ px: 0 }}>
              <ListItemText
                primary={`${index + 1}. ${attendee.firstName} ${
                  attendee.lastName
                }`}
                secondary={`${calculateAge(attendee.birthdate)} years old, ${
                  attendee.sex
                }`}
              />
            </ListItem>
          ))}
        </List>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        my: 4,
        mx: 2,
        width: "100%",
        maxWidth: "1200px",
        margin: "0 auto",
        pt: isMobile ? 5 : 0,
      }}
    >
      {upcomingClasses.length === 0 ? (
        <Typography>
          <FormattedMessage
            id="upcomingClasses.noClasses"
            defaultMessage="You have no upcoming classes."
          />
        </Typography>
      ) : (
        <Grid container spacing={2}>
          {upcomingClasses.map((classItem) => (
            <Grid item xs={12} md={6} key={classItem.id}>
              <Card
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                }}
              >
                <CardContent sx={{ flex: 1 }}>
                  <Typography variant="h6" component="div">
                    {getLocalizedValue(classItem.title, language)}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {intl.formatMessage({ id: "upcomingClasses.nextClass" })}:{" "}
                    {formatClassTime(classItem.nextSchedule!)}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {intl.formatMessage({ id: "upcomingClasses.instructor" })}:{" "}
                    {classItem.instructor.firstname}{" "}
                    {classItem.instructor.lastname}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {intl.formatMessage({ id: "upcomingClasses.location" })}:{" "}
                    {getLocalizedValue(classItem.location.name, language)}
                  </Typography>
                </CardContent>
                {!isMobile && (
                  <Box
                    sx={{
                      width: "30%",
                      borderLeft: "1px solid",
                      borderColor: "divider",
                      p: 2,
                    }}
                  >
                    {renderAttendees(classItem)}
                  </Box>
                )}
                {isMobile && (
                  <Box sx={{ p: 2 }}>{renderAttendees(classItem)}</Box>
                )}
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
}
